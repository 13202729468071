import {IControllerFactoryConfig} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/createViewerScript';
import {I$W, IWidgetController} from '@wix/native-components-infra/dist/es/src/types/types';
import {CartIconStore} from './CartIconStore';
import {ICartIconControllerConfigEditor} from '../types/app-types';
import {withErrorReporting} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {IAddToCartOptions} from '../types/product';
import {IAddToCartItem} from '@wix/wixstores-client-core/dist/es/src/types/cart';
import {createCartExports} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/wixcode/cart/wixcode.cart';
import {FedopsInteraction, origin, specs} from '../constants';
import {Scope} from '@wix/app-settings-client/dist/src/domain';

export function cartWidgetController({
  config,
  setProps,
  context,
  reportError,
}: IControllerFactoryConfig): IWidgetController {
  const {addToCart, addProducts} = createCartExports({context, origin});
  let cartIconStore: CartIconStore;
  const {siteStore} = context;

  return {
    pageReady: () => {
      cartIconStore = new CartIconStore(context.siteStore, config, setProps, reportError);
      if (siteStore.experiments.enabled(specs.stores.ShouldShowZeroUntilLoadCartIcon)) {
        return cartIconStore.setInitialStateAsync();
      }
      return cartIconStore.setInitialState();
    },
    updateConfig: (_$w: I$W, updatedConfig: ICartIconControllerConfigEditor) => {
      config.style = updatedConfig.style;
      config.publicData = updatedConfig.publicData;
      if (siteStore.experiments.enabled(specs.stores.EDITOR_OOI)) {
        setProps({
          displayText: cartIconStore.getDisplayTextOOI(),
        });
      } else {
        setProps({
          displayText: cartIconStore.getDisplayText(
            updatedConfig.publicData ? updatedConfig.publicData.appSettings : {}
          ),
        });
      }
    },
    updateAppSettings: (_$w: I$W, updates) => {
      if (
        siteStore.experiments.enabled(specs.stores.EDITOR_OOI) &&
        updates.scope === Scope.COMPONENT &&
        updates.source === 'app-settings'
      ) {
        cartIconStore.updateAppSettings(updates.payload);
        setProps({
          displayText: cartIconStore.getDisplayTextOOI(),
        });
      }
    },
    onBeforeUnLoad: () => {
      cartIconStore.unSubscribeAll();
    },
    exports: () =>
      withErrorReporting(reportError)({
        addToCart: async (
          productId: string,
          quantity: number = 1,
          options: IAddToCartOptions = {}
        ): Promise<boolean> => {
          await cartIconStore.executeWithFedops(FedopsInteraction.ADD_TO_CART, () =>
            addToCart(productId, quantity, options)
          );
          return true;
        },
        addProductsToCart: async (cartItems: IAddToCartItem[]): Promise<boolean> => {
          await cartIconStore.executeWithFedops(FedopsInteraction.ADD_ITEMS_TO_CART, () => addProducts(cartItems));
          return true;
        },
      }),
  };
}
